@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 16px;
  font-family: "Poppins", Helvetica;
  font-weight: 400;
  font-style: normal;
  color: #000;
  background-color: #181a1d;
  position: relative;
  /* min-height: 100%; */
}

ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

.gldn-home-draft-2 {

  border: 1px none;
  display: block;
  /* overflow: hidden; */
  width: 100%;
  /* height: 100vh; */
  /* position: relative; */
  z-index: 999;
}

.navbar{
  z-index: 100;
}

.navbar-toggler-icon{
  background-image: url('./images/menu.png');
}

.navbar-nav{
  margin: 10px;
}

.gldn-home-draft-2 .group {
  height: 1268px;
  position: absolute;
  right: 0px;
  top: 0;
  width: 719px;
  padding: 0;
  z-index: 0;
}

.gldn-home-draft-2 .group-1 {
  height: 931px;
  left: 0;
  position: absolute;
  top: 0;
  width: 132px;
  padding: 0;
  z-index: 0;
}

.table-area {
  position: relative;
  z-index: 999;
}

.search-box {
  position: relative;
  top: auto;
  left: auto;
  padding: 0;
  background-color: transparent;
}

.search-box input {
  color: #fff;
  font-weight: 400;
  border-color: #f2c94c;
  background: transparent;
}

.form-control:focus {
  border-color: #f2c94c;
  background: transparent;
  color: #fff;
  box-shadow: none;
}

.search-box button {
  position: absolute;
  right: 10px;
  top: 8px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
}

.search-box button svg {
  width: 20px;
  height: auto;
  fill: #bdbdbd;
  transition: 0.5s ease;
}

.table {
  color: #fff;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: #fff;
}

.table-hover>tbody>tr:hover>* {
  color: #fff;
}

.badges {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.gold-badge {
  opacity: 1;
  background-color: #e5b841;
}

.sliver-badge {
  opacity: 1;
  background-color: #919191;
}

.copper-badge {
  opacity: 1;
  background-color: #c17b47;
}

.paginator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto 0;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  border: 1px solid #f2c94c;
}

.paginator-pages {
  display: none;
}

.paginator-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.paginator-list li {
  margin-right: 15px;
}

.paginator-list li {
  margin-right: 15px;
}

.paginator-list a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  font-size: 14px;
  color: #b7b7b7;
  font-weight: 400;
}

.paginator-list a svg {
  fill: #b7b7b7;
}

.paginator-list li.active a {
  color: #f2c94c;
  cursor: default;
  font-weight: 500;
}

.loader-area {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(214 172 53 / 50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media screen and (min-width: 768px) {
  .paginator {
    padding: 0 20px;
    margin: 20px auto 0;
    height: 60px;
    justify-content: space-between;
  }

  .paginator-pages {
    display: block;
    font-size: 16px;
    color: #b7b7b7;
    font-weight: 400;
  }
}

.btncsv {
  border: 1px solid;
  border-color: #f2c94c;
  border-radius: .375rem;
  display: block;
  overflow: hidden;
  padding: 8px 12px 8px 16px;
  width: fit-content;
  color: #fff;
}

.btncsv:hover {
  border-color: #fff;
  color: #f2c94c;
}

.nav-link {
  color: #FFF;
}

.nav-link:hover {
  color: #d9ae33;
}

h1,
h2,
h3,
h4,
h5 {
  color: #FFF;


}

.head-text {
  color: #d9ae33;
  font-weight: 700;
  font-size: 50px;

}

.shimmer {
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1000px 100%;
  animation: shimmer 2.2s linear infinite forwards;

}

.shimmer-thumbnail {
  min-width: 80px;
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  display: inline-block;
  height: 10px;
}

.nav-link {
  border: 1px solid #f2c94c;
  border-color: #f2c94c;
  border-radius: 18px;
  display: block;
  overflow: hidden;
  padding: 0.8rem;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  z-index: 100;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  padding-top: 6px;
  padding-bottom: 7px;
  margin-bottom : 10px
}

.btn-cust:hover {
  border-color: #fff;
  color: #f2c94c;
}

.table>thead {
  vertical-align: bottom;
  color: #e5b841;
}

.silver-badge {
  background-color: #8c8c8c;
}

.copper-badge {
  background-color: #b6682d;
}

a.btn.btn-cust.nav-link.active {
  border: 1px solid #f2c94c;
  border-color: #f2c94c;
  border-radius: 18px;
  display: block;
  overflow: hidden;
  padding: 0.8rem;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  z-index: 100;
}